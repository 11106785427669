import axios from 'axios';
import { apiV4 } from '@app/services/HttpService';

export const ttsDefaultOptions = {
  format: 'opus',
  voice: 'Bys_24000',
  numChannels: 1,
  sampleRate: 22050,
  play: false,
};

class TtsService {
  constructor(options = {}) {
    this.options = {
      voice: options.voice || ttsDefaultOptions.voice,
      sampleRate: options.sampleRate || ttsDefaultOptions.sampleRate,
      numChannels: ttsDefaultOptions.numChannels,
    };

    const AudioContext = window.AudioContext || window.webkitAudioContext;
    this.audioCtx = new AudioContext();
    this.sourceNodes = [];
    this.buffers = [];
    this.pausedAt = null;
    this.paused = false;
    this.startedAt = null;
    this.playSecond = false;
    this.playThird = false;
    this.currentSourceIndex = null;
    this.cancelTokens = [];
  }

  loadData(text) {
    const token = axios.CancelToken.source();
    this.cancelTokens.push(token);

    return apiV4.post('tts/audio',
      {
        voiceName: this.options.voice,
        text,
        format: 'opus',
      },
      {
        responseType: 'arraybuffer',
        cancelToken: token.token,
      })
      .then(({ data }) => this.decodeAudioData(data)
        .then((buffer) => {
          const sourceNode = this.createSourceNode(buffer);
          this.sourceNodes.push(sourceNode);
          this.buffers.push(buffer);
          return buffer;
        }))
      // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  createSourceNode(buffer) {
    const sourceNode = this.audioCtx.createBufferSource();
    sourceNode.connect(this.audioCtx.destination);
    sourceNode.buffer = buffer;
    return sourceNode;
  }

  decodeAudioData(data) {
    return new Promise((resolve, reject) => {
      this.audioCtx.decodeAudioData(data, buffer => resolve(buffer), err => reject(err));
    });
  }

  playAudio() {
    this.play();
  }

  pauseAudio() {
    this.stop();
  }

  setDataLoadedCallback(loaded) {
    if (typeof this.onFinishLoading === 'function') {
      this.onFinishLoading(loaded);
    }
  }

  clearAudios() {
    this.buffers = [];
    this.sourceNodes.map(node => node.disconnect(this.audioCtx.destination));
    this.sourceNodes = [];
    this.playSecond = false;
    this.playThird = false;
    this.currentSourceIndex = null;
  }

  clearTimings() {
    this.pausedAt = null;
    this.paused = false;
    this.startedAt = null;
  }

  cancelRequests() {
    this.cancelTokens.map((token, index) => token.cancel(`cancel request ${index}`));
    this.cancelTokens = [];
  }

  clearData() {
    this.clearAudios();
    this.clearTimings();
    this.cancelRequests();
    this.setDataLoadedCallback(false);
  }

  play() {
    if (typeof this.onAudioPlaying === 'function') {
      this.onAudioPlaying();
    }

    const onEndedCallback = this.sourceNodes[this.currentSourceIndex].onended;
    this.sourceNodes[this.currentSourceIndex] = this.createSourceNode(this.buffers[this.currentSourceIndex]);
    this.sourceNodes[this.currentSourceIndex].onended = onEndedCallback;

    if (this.sourceNodes[this.currentSourceIndex]) {
      if (this.pausedAt) {
        this.startedAt = Date.now() - this.pausedAt;
        this.sourceNodes[this.currentSourceIndex].start(0, this.pausedAt / 1000);
      } else {
        this.startedAt = Date.now();
        this.sourceNodes[this.currentSourceIndex].start(0);
      }
    }

    this.paused = false;
  }

  stop() {
    if (typeof this.onAudioPaused === 'function') {
      this.onAudioPaused();
    }

    if (this.sourceNodes[this.currentSourceIndex]) {
      this.sourceNodes[this.currentSourceIndex].stop(0);
    }
    this.pausedAt = Date.now() - this.startedAt;
    this.paused = true;
  }

  synthesize(text) {
    this.loadData(text)
      .then(() => {
        this.setDataLoadedCallback(true);
        this.currentSourceIndex = 0;

        this.sourceNodes[0].onended = () => {
          if (!this.paused) {
            if (typeof this.audiosEnded === 'function') {
              this.audiosEnded();
            }
            this.currentSourceIndex = null;
          }
        };

        if (typeof this.audioPlaying === 'function') {
          this.onAudioPlaying();
        }

        this.clearTimings();
        this.play();
        return null;
      })
      // eslint-disable-next-line no-console
      .catch(e => console.error(e));
  }
}

export default TtsService;
